


















import { defineComponent, useRouter, useContext } from '@nuxtjs/composition-api';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
   name: 'FortytwoFooterSection5',
   setup() {
    const router = useRouter();
    const { app } = useContext();
    const customerStore = useCustomerStore();

    const goToMyAccountHandle = async () => {
        if (app.$vsf.$magento.config.state.getCustomerToken()) {
            await router.push(app.localeRoute({ name: 'customer-my-profile' }));
        } else {
            await customerStore.setTriggerLoginModal(true);
        }
    };

    return {
        goToMyAccountHandle,
    }
   }
})
