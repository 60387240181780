






























































































import { defineComponent, ref, computed, useContext } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import moment from 'moment';
import Swal from "sweetalert2";
import section1 from "~/components/FortyTwo/footer/section1.vue";
import section2 from "~/components/FortyTwo/footer/section2.vue";
import section3 from "~/components/FortyTwo/footer/section3.vue";
import section4 from "~/components/FortyTwo/footer/section4.vue";
import section5 from "~/components/FortyTwo/footer/section5.vue";
import section6 from "~/components/FortyTwo/footer/section6.vue";
import section7 from "~/components/FortyTwo/footer/section7.vue";
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { useUiNotification, useNewsletter } from '~/composables';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'Invalid email format',
});

const BASE_FORM = () => ({
    newsletterEmail: '',
});

export default defineComponent({
    name: 'FortytwoAppFooter',
    components: {
        SfInput,
        section1,
        section2,
        section3,
        section4,
        section5,
        section6,
        section7,
        ValidationObserver,
        ValidationProvider,
    },
    setup() {
        const currentyear = moment().format("YYYY");
        const { app: { i18n } } = useContext();
        const { send: sendNotification } = useUiNotification();

        const promptAlert = (icon, comment) => {
            Swal.fire({
                position: "top-end",
                icon: icon,
                title: comment,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
                toast: true,
                grow: "row",
            });
        }

        // Declare Form
        const form = ref(BASE_FORM());
        const { updateSubscription, error: newsletterError, loading: newsletterLoading } = useNewsletter();
        const updateSubscriptionError = computed(() => newsletterError.value?.updateSubscription?.message);

        const submitForm = (reset: () => void) => async () => {
            await updateSubscription({ email: form.value.newsletterEmail });
            if (updateSubscriptionError.value) {
                sendNotification({
                    id: Symbol('update_subscription_error'),
                    message: updateSubscriptionError.value,
                    type: 'danger',
                    icon: '',
                    persist: false,
                    title: 'Subscription Notification',
                });
            } else {
                sendNotification({
                    id: Symbol('update_subscription_error'),
                    message: i18n.t('Thank you for your subscription.') as string,
                    type: 'success',
                    icon: 'check',
                    persist: false,
                    title: 'Subscription Notification',
                });
            }
            form.value.newsletterEmail = '';
            reset();
        };

        return {
            currentyear,
            form,
            submitForm,
            newsletterLoading,
        }
    }
})
